<template>
	<div class="pr-20 flex-column full-height bg-base  size-px-16 overflow-y-auto">
		<div class="">
			<div class="justify-space-between gap-20 font-weight-bold ">
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">TODAY</div>
					<div class="mt-10 size-px-20">{{ search.sDate }}</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">회원 가입 현황</div>
					<div class="mt-10 size-px-20">{{ item.join_today | makeComma}} / {{ item.total_join | makeComma}} 명</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">충전주문 ({{ item.total_charge | makeComma }}) 건</div>
					<div class="mt-10 size-px-20">{{ item.total_charge_price | makeComma }}원</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">일반 상품 주문 ({{ item.total_order_normal | makeComma }}) 건</div>
					<div class="mt-10 size-px-20">{{ item.total_order_normal_price | makeComma }}원</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">기업 상품 주문 ({{ item.total_order_business | makeComma }}) 건</div>
					<div class="mt-10 size-px-20">{{ item.total_order_business_price | makeComma }}원</div>
				</div>
			</div>
			<div class="mt-20">
				<div class="justify-space-between">
					<GChart
						type="ColumnChart"
						:data="chart_data_1"
						:options="chartOptions1"
						class="flex-1 mr-10 box"
					/>
					<GChart
						type="ColumnChart"
						:data="chart_data_2"
						:options="chartOptions2"
						class="flex-1 mr-10 box"
					/>
					<GChart
						type="ColumnChart"
						:data="chart_data_3"
						:options="chartOptions3 "
						class="flex-1 box"
					/>
				</div>
			</div>

			<div class="mt-20 bg-white pa-10">
				<div><span class="font-weight-bold">주문 현황</span> <span class="color-gray-light size-px-14 hidden">최근 1개월 이내</span></div>
				<table class="table mt-15">
					<colgroup>

					</colgroup>
					<thead>
					<tr>
						<th>구분</th>
						<th>결제완료</th>
						<th>배송전</th>
						<th>배송중</th>
						<th>배송완료</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(order, o_index) in items_order"
						:key="'order_' + o_index"
					>
						<td>{{ order.type_name }}</td>
						<td>{{ order.order_count | makeComma}} 건</td>
						<td>{{ order.odt1_count | makeComma}} 건</td>
						<td>{{ order.odt2_count | makeComma}} 건</td>
						<td>{{ order.odt3_count | makeComma}} 건</td>
					</tr>
					</tbody>
				</table>
			</div>

			<div class="mt-15 justify-space-between gap-20 ">
				<div class="bg-white pa-10 flex-1 ">

					<div><span class="font-weight-bold">충전 현황</span> <span class="color-gray-light size-px-14 hidden">최근 1개월 이내</span></div>
					<div class="mt-10 justify-space-between gap-20 items-center text-center">
						<div class="flex-1 box">
							<div class="bg-title font-weight-bold pa-10 under-line size-px-12">충전 주문건</div>
							<div class="ptb-30">
								{{ item_charge.charge_count | makeComma}} 건
							</div>
						</div>
						<div class="flex-1 box">
							<div class="bg-title font-weight-bold pa-10 under-line size-px-12">충전 요청 금액</div>
							<div class="ptb-30">
								{{ item_charge.charge_price | makeComma}} 원
							</div>
						</div>
						<div class="flex-1 box">
							<div class="bg-title font-weight-bold pa-10 under-line size-px-12">충전 완료 금액</div>
							<div class="ptb-30">
								{{ item_charge.charge_order_price | makeComma}} 원
							</div>
						</div>
					</div>
				</div>

				<div class="bg-white pa-10 flex-1 ">
					<div><span class="font-weight-bold">클레임 현황</span> <span class="color-gray-light size-px-14 hidden">최근 1개월 이내</span></div>
					<table class="table mt-15">
						<colgroup>

						</colgroup>
						<thead>
						<tr>
							<th>구분</th>
							<th>취소 요청</th>
							<th>반품 요청</th>
							<th>교환 요청</th>
						</tr>
						</thead>
						<tbody>
						<tr
							v-for="(claim, c_index) in items_claim"
							:key="'claim_' + c_index"
						>
							<td>{{ claim.type_name }}</td>
							<td>{{ claim.cancel_count | makeComma}} 건</td>
							<td>{{ claim.return_count | makeComma}} 건</td>
							<td>{{ claim.change_count | makeComma}} 건</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>


			<div
				class=" mt-15 justify-space-between gap-20 mb-50"
			>
				<div class="bg-white flex-1 pa-10">
					<div class="justify-space-between" @click="toQna">
						<h6 class="">최근 문의</h6>
						<button
						><v-icon>mdi-plus-circle</v-icon></button>
					</div>
					<ul class="    ">
						<template
							v-if="qna_items.length > 0"
						>
							<li
								v-for="qna in qna_item_list"
								:key="'qna_' + qna.uid"
								class="ptb-10 justify-space-between under-line-not-last"
								@click="toQnaDetail(qna)"
							>
								<span>[{{ qna.is_answer }}] {{  qna.b_title  }}</span>
								<span>{{  qna.wDate | transDate('-')}}</span>
							</li>
						</template>
						<li
							v-else
						>등록된 문의가 없습니다</li>
					</ul>
				</div>
				<div class="bg-white flex-1 pa-10">
					<div class="justify-space-between" @click="toNotice">
						<h6 class="">공지사항</h6>
						<button
						><v-icon>mdi-plus-circle</v-icon></button>
					</div>
					<ul class=" ">
						<template
							v-if="notice_items.length > 0"
						>
							<li
								v-for="notice in notice_items"
								:key="'notice_' + notice.uid"
								class="ptb-10 justify-space-between under-line-not-last"
								@click="toNoticeDetail(notice)"
							>
								<span>{{  notice.b_title  }}</span>
								<span>{{  notice.wDate | transDate('-')}}</span>
							</li>
						</template>
						<li
							v-else
						>공지사항이 없습니다</li>
					</ul>
				</div>
			</div>
			<div class="mt-auto pa-10 "></div>
		</div>
	</div>
</template>

<script>

import { GChart } from 'vue-google-charts'

export default{
	name: 'Main'
	,props: ['Axios', 'TOKEN', 'date', 'user', 'codes']
	,components: { GChart }
	,data: function(){
		return {
			program: {
				name: '대시보드'
				,top: true
				,title: true
				,search: false
				,bottom: false
			}
			,member_info: {

			}
			, search: {
				sDate: this.$date.getToday('-')
			}
			,items: [
				{}, {}, {}, {}
			]
			,item: {

			}
			,chartData: [
				['Month', '판매량', '매출', '판매량'],
				['2021.09.13',  165,      938,         522],
				['2021.09.14',  135,      1120,        599],
				['2021.09.15',  157,      1160,        587],
				['2021.09.16',  139,      1110,        615],
				['2021.09.17',  136,      691,         629]
			]
			,chartOptions1: {
				title : '최근 1주일 충전 판매량',
				height: 350,
				seriesType: 'bars'
				,vAxis: {format: 'short'}
			}
			,chartOptions2: {
				title : '최근 1주일 일반 상품 판매량',
				height: 350,
				seriesType: 'bars'
				,vAxis: {format: 'short'}
			}
			,chartOptions3: {
				title : '최근 1주일 기업 상품 판매량',
				height: 350,
				seriesType: 'bars'
				,vAxis: {format: 'short'}
			}
			,chart_data1: []
			,chart_data2: []
			,chart_data3: []
			,settlement_data: {
				total_cnt: 0
			}
			,qna_items: []
			,notice_items: []
			, items_order: []
			, item_charge: {
				charge_count: 0
				, charge_price: 0
				, charge_order_price: 0
			}
			, items_claim: [

			]
		}
	}
	,computed: {
		qna_item_list: function(){
			return this.qna_items.filter(function(item){
				if(item.b_answer){
					item.is_answer = '답변완료'
				}else{
					item.is_answer = '답변대기'
				}
				return item
			})
		}
		,chart_data_1: function(){
			let data = [
				["판매일", "매출(단위 천원)", "판매량"]
			]

			if(this.chart_data1.length > 0){
				this.chart_data1.filter(function(item){
					data.push([
						item.date
						,Number(item.total_sale_price ? item.total_sale_price / 1000 : 0)
						,Number(item.total_cnt ? item.total_cnt : 0)
					])
				})
			}else{
				data.push([
					''
					,0
					,0
				])
			}

			return data
		}
		,chart_data_2: function(){
			let data = [
				["판매일", "매출(단위 천원)", "판매량"]
			]

			if(this.chart_data2.length > 0){
				this.chart_data2.filter(function(item){
					data.push([
						item.date
						,Number(item.total_sale_price ? item.total_sale_price / 1000 : 0)
						,Number(item.total_cnt ? item.total_cnt : 0)
					])
				})
			}else{
				data.push([
					''
					,0
					,0
				])
			}

			return data
		}
		,chart_data_3: function(){
			let data = [
				["판매일", "매출(단위 천원)", "판매량"]
			]

			if(this.chart_data3.length > 0){
				this.chart_data3.filter(function(item){
					data.push([
						item.date
						,Number(item.total_sale_price ? item.total_sale_price / 1000 : 0)
						,Number(item.total_cnt ? item.total_cnt : 0)
					])
				})
			}else{
				data.push([
					''
					,0
					,0
				])
			}

			return data
		}
	}
	,methods: {
		getData: async function(){
			const result = await this.$request.init({
				method: 'post'
				,url: 'management/getDashboard'
				,data: {
				}
			})

			if(result.success){
				this.item = result.data
				this.items_order = result.data.items_order
				this.item_charge = result.data.item_charge
				this.items_claim = result.data.items_claim

				this.chart_data1 = result.data.chart_data1
				this.chart_data2 = result.data.chart_data2
				this.chart_data3 = result.data.chart_data3
				this.settlement_data = result.data.settlement_data
				this.qna_items = result.data.qna_list
				this.notice_items = result.data.notice_list
			}else{
				this.$bus.$emit('notify', { type: 'error', message: result.message})
			}
		}
		,goDetail(item){
			item.TOKEN = sessionStorage.getItem('delimallAT')
			this.$set(this, 'item', item)
		}
		,clear(){
			this.$set(this, 'item', {})
			this.setProgram(this.program)
		}
		,setProgram(program){
			this.$emit('onLoad', program)
		}
		,setNotify({ type, message}){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		,toQnaDetail: function(item){
			this.$router.push({ name: 'BbsAnswer', params: { b_code: item.b_code, bbs_uid: item.uid}})
		}
		,toNoticeDetail: function(item){
			if(this.user.role == this.codes.type_code_admin){
				this.$router.push({ name: 'BbsDetail', params: { b_code: item.b_code, bbs_uid: item.uid}})
			}else{
				this.$router.push({ name: 'BbsView', params: { b_code: item.b_code, bbs_uid: item.uid}})
			}
		}
		, toQna: function(){
			this.$storage.push({ name: 'CustomerCenterQnAList'})
		}
		, toNotice: function(){
			this.$storage.push({ name: 'CustomerCenterNoticeList'})
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
		this.$router.push({ name: 'DashboardMulti'})
		// this.getData()
	}
}

</script>

<style>

.label { color: white; border-radius: 5px; padding: 3px 7px 3px; line-height: 12px !important; font-size: 10px;}
.label-new { background-color: #0D47A1;}
.label-hot { background-color: #e64a19;}
.label-recomm { background-color: #0f9d58;}
.label-season { background-color: #00b0ff;}
</style>
